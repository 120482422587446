// Wholesale Overrides

#wholesale-app {
  
  h2 {
    font-weight: 700;
    font-size: 36px;
  }
  h1,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-weight: 600;
  }
  .ff-body,
  html,
  body {
    font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  }
  .btn-darker,
  .btn-darker-outline {
    width: 300px !important;
    max-width: 100%;
    
    &.w-100 {
      max-width: 100%;
      width: 100% !important;
    }
  }

  footer {
    *:not(.btn) {
      color: #aeaba3;
    }
  }

  form {
    input,
    input[type="email"] {
      background: #e8f0fe;
    }
  }

  p {
    font-size: 16px;

    &.disclaimer {
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
    }
    a {
      color: #128851;
    }
  }
  .fz-18 {
    font-size: 18px;
  }

  .content {
    p {
      font-size: 18px;
    }
  }

  .accordion {
    .card-header {
      background: white;
      border: none;
    }
  }
  a[data-toggle="collapse"] {
    i.fa-plus:before {
      content: "\f067";
    }
    &.collapsed {
      i.fa-plus:before {
        content: "\f067";
      }
    }
    &[aria-expanded="true"] {
      i.fa-plus:before {
        content: "\f068";
      }
    }
  }
  .card-header {
    background: white;
  }
  .text-decoration-underline {
    text-decoration: underline;
  }
  .text-success {
    color: #128851 !important;
  }
  .checkout-pager {
    background-color: #eeeeee;
  }
}
