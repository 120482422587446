*,
*:before,
*:after{
  box-sizing: border-box;
}
html {
  scroll-padding-top: 120px;
}

html,
body{  
  // position: relative;
  @extend .ff-body;
  color: $font-color;
  line-height: $font-line-height;
  font-size: $font-size;
  // overflow-x: hidden;

    &.ov-hidden {
      overflow: hidden;
    }
}

// @iOS_Safari elastic scroll fix
// configure based on markup
body {
  background-color: #FFF;
  // padding-top: 3rem;
  // height: 100vh;
  // width: 100vw;
  // overflow: hidden;
  // #mobile-wrap {
  //   width: 100vw;
  //   overflow: scroll;
  //   overflow-x: hidden;
  // }
}

h1, h2, h3, h4, h5, h6{
  @extend .ff-header;
}


a{
	color: $link-color;
	text-decoration: none;
  transition: all .4s ease;

	&:hover {
		color: $link-color-hover;
		text-decoration: underline;
	}
}
// add underline to links for ada
p, .add-to-cart_body {
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
// a,
// button {
//   &:hover,
//   &:focus,
//   &:active {
//     outline: none !important;
//   }
// }

pre, code{
  font-size: 12px; 
}

.text-center{
  text-align: center;
}

.container-fluid{

  &.container-max-width{
    max-width: 1140px !important;
  }
}

.content-wrapper{
  min-height: 500px;
}

.float-left{
  float: left;
}

.bg-image{
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

p.disclaimer{
  font-size: 12px;
}

.text-wrap{
  white-space: normal;
}

.text-underline {
  text-decoration: underline;
}

@media screen and (max-width: 992px) {
  #mobile-wrap {
    // padding-top: 76px;
  }
}

@media screen and (min-width: 769px) {
  .hidden-md-up-fix {
    display:none;
  }
}

