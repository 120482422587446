














































.order-form-wrapper {
    position: relative;
    #updating-overlay {
        width: 100vw;
        position: fixed;
        top: 69.68px;
        height: calc(100vh - 69.68px);

        background-color: rgba(#fff, .25);
        color: #000;
        font-size: 1.5rem;

        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        > span {
            display: inline-block;
            padding: .75rem 2rem;
            border-radius: .5rem;
            background: #fff;
            box-shadow: 0px 2px 4px rgba(#000,.1);

            span {
                margin-left: .5rem;
            }
        }
    }
}
#filter-container {
    position: sticky;
    top: 69.68px; // nav height
    display: block;
    width: 100%;
    box-shadow: 0px 2px 4px rgba(#000, .1);
    margin-bottom: 1rem;
    background-color: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    z-index: 3;

    a.toggle-filters {
        font-size: 16px;
        display: inline-block;
    }
    @media screen and (max-width: 600px) {
        top: 58px;
    }

    .search-container{
        width: 50%;

        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }
}
#shop-container {
    min-height: calc(100vh - 104px - 76px - 69.86px);
}
#order-container {
    position: sticky;
    bottom: 0px;
    background: #fff;
    box-shadow: 0px -2px 4px rgba(#000, .1);
    z-index: 3;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: fixed;
        width: 100%;
    }
}
h1 {
    font-weight: bold;
    font-size: 2rem;
}
