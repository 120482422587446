// Color Support Classes

//Standard color support
$colors: black, white, grey;
@each $color in $colors {
	.color-#{$color} {
		color: $color !important;
	}
	.bg-#{$color} {
		background-color: $color !important;
	}
	.hover-bg-#{$color} {
		transition: all .3s;
		&:hover {
			background-color: $color !important;
		}
	}
	.border-#{$color} {
		border-color: $color !important;
	}
}

//Brand color support
@each $color in $brand-colors {
	.color-brand-#{nth($color, 1)} {
		color: nth($color, 2) !important;
	}
	.bg-brand-#{nth($color, 1)} {
		background-color: nth($color, 2) !important;
	}
	.hover-bg-brand-#{nth($color, 1)} {
		transition: all .3s;
		&:hover {
			background-color: nth($color, 2) !important;
		}
	}
	.border-brand-#{nth($color, 1)} {
		border-color: nth($color, 2) !important;
	}
}