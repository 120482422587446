section.upload-wrapper {

		.alert-box {
			background: none;
			margin-bottom: 25px;

				h2 {
					line-height: 100%;
					margin-bottom: 0;
				}
		}

		.label {
			font-size: 18px;
		}

		form {

				.control-group {

						.custom-file {
							width: 100%;
						}

						.custom-file-control::before {
							background-color: #128851;
					    color: #FFF;
					    border:none;
						}

						#customFile .custom-file-control:lang(en)::before {
						  content: "Choose...";
						}

						.custom-file-control:lang(en)::after {
						  content: "" !important;
						}

						.custom-file {
						  overflow: hidden;
						}
						.custom-file-control {
						  white-space: nowrap;
						}
				}

				input.upload-btn {
					width: 100%;
				    padding: 8px 6px;
		    		background-color: #128851;
			        color: #FFF;
			        border:none;
			        cursor: pointer;
				}
		}
}