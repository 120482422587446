section.mobile-search-form {
	margin-top: 24px;
	margin-bottom: 0;

	form {
		background: #FFFFFF;
		border: 1px solid #E5E5E4;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
		border-radius: 4px;

		.input-group {
			border: none;
			width: 100%;
		}

		.input-group-text {
			background-color: #fff;
			border: none;

			.fa {
				color: #AFBBC6;
				font-size: 24px;
			}
		}

		input {
			border: none !important;
			// font-family: SourceSansPro-Regular;
			@include ff-sans-serif();
			font-size: 16px;
			color: #AFBBC6;
			letter-spacing: 0;
			min-height: 48px;
		}
	}

	p {
		text-align: center;
		margin: 15px 0;
		line-height: 1.3;
	}
}