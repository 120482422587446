















input.form-control {
    min-width: 60px;
    &.has-value {
        border: 2px solid #128851;
    }
    &.has-error {
        border: 2px solid red !important;
    }
}
