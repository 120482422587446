// @group smart search
#search-input-nav {
    position: relative;
    font-family: 'Roboto';

    #smart-search {
        position: absolute;
        left: 0;
        top: calc(100% + .25rem);
        right: 0;
        height: auto;
        z-index: 10;

        @media screen and (max-width: 768px) {
            right:auto;
            width:90vw;
        }

        // @loading indicator
        .loading {
            .list-group-item {
                // color: #666;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                span {
                    &+span {
                        margin-left: .4rem;
                    }
                }
            }
        }

        // @results list
        .results {
            box-shadow: 0px 2px 6px rgba(0,0,0,.2);
            max-height: 40vh;
            overflow-y: scroll;
            .result-item {
                padding: .45rem;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;

                // @header
                &.result-item-header {
                    justify-content: center;
                    padding: .25rem .75rem;
                    background-color: #f1f1f1;
                    font-weight: bold;
                    display: none;
                }

                .result-item-content {
                    flex-grow: 1;
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    
                    .result-item-img {
                        margin-right: .75rem;
                        width: 3.75rem;
                        min-height: 3.75rem;
                        background-size: cover;
                        background-position: center center;
                        background-image: url('/assets/images/natorps_missing_image@2x.jpg');
                        img {
                            width: 3.75rem;
                            height: auto;;
                        }
                    }
                    .result-item-meta {
                        line-height: 1.2;
                        .title {
                            font-weight: bold;
                            font-size: 15px;
                        }
                        .subtitle {
                            font-size: 14px;
                        }
                    }
                }

                .result-item-icon {
                    flex-shrink: 1;
                    font-size: 2rem;
                    line-height: 0;
                    color: #ddd;
                    padding-right: .25rem;
                }
            }
        }
    }
}


/// @group Search
#search-input-form {

	#searchBox {
		border-radius: 0px;
	}
	.input-group-append button {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	@include media-breakpoint-down(sm) {
		top: 78.42px;
		left: 0;
		right: 0;

		width: 100%;
		padding: .5rem 1.2rem;

		background-color: #eee;
		box-shadow: 0px 2px 4px rgba(#000, .1);

		.input-group {
			background-color: #fff;
			border-radius: 4px;
		}
		.form-control {
			height: calc(2.25rem + 8px);
			padding: 0.375rem 0.75rem;
		}
		button,
		.input-group-append,
		.input-group-prepend,
		.input-group-text {
			@include on-action() {
				color: #AFBBC6;
				opacity: initial;
			}
		}

		.smart-search-wrapper {
			.list-group {
				&-item {
					display: flex;
					flex-shrink: 0;
					flex-grow: 0;
				}
			}
		}
	}	
}