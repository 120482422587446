.header {
	position: sticky;
	top:59px;
	z-index: 1001;

	#wholesale-app & {
		top:0 !important;
	}

	@media screen and (max-width: 768px) { 
		top:58px;
	}

	#search-input-nav {
        font-family: $font-family-sans-serif;
    }

	.impersonation-bar {
		padding: 10px;

		a {
			color: $white;
			text-decoration: underline;
		}
	}

	// @fixed-nav-offset
	.navbar-nav .nav-item+.nav-item,
	.navbar-nav .nav-link+.nav-link {
		margin-left: 1.75rem;
	}

	nav.navbar.top-nav {
		background-color: #128851;
		justify-content: space-between;
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 8px;
		padding-bottom: 8px;
		border-radius: 0;

		a.navbar-brand {
			margin-right: 32px;

			img.natorps-logo {
				max-width: 122px;
				width: 122px;
				// width: 100%;
				height: auto;

				@media screen and (max-width: 768px) {
					max-width: 86px;
				}
			}
		}

		form {
			flex-grow: 1;
			margin-right: 40px;

			.input-group {
				width: 100%;

				input {
					height: 48px;
					border-right: none;
					@include ff-sans-serif();
					font-weight: 400;
					font-size: 18px;
					color: #FFF;
					letter-spacing: 0;
					border-radius: 4px;
					border-radius: 0px 4px 4px 0px;
					border: none;
					background-color: rgba(0, 0, 0, .16);

					&::placeholder {
						color: #fff;
					}

					&:active,
					&:focus {
						background-color: #FFF;
						color: #000;
					}
				}

				.input-group-text {
					background-color: rgba(0, 0, 0, .16);
					border: none;
					border-radius: 4px 0px 0px 4px;
					color: rgba(255, 255, 255, .70);
					font-size: 24px;
					cursor: pointer;
					// transition: all .4s ease;

					&:hover {
						color: rgba(255, 255, 255, 1);
					}

					&.focus {
						background-color: #FFF;
						color: #128851;
					}
				}
			}

			@media screen and (max-width: 768px) {
				position: absolute;
				z-index: -1;
				margin-right: 0;
				left: 16px;
				width: 50%;
				// min-width: 240px;
				top: 8px;
				display: none;

				&.display {
					z-index: 999;
					display: block;
				}

				.input-group {

					input {
						background-color: rgba(255, 255, 255, 1);
						font-size: 16px;
						height: 40px;

						&::placeholder {
							color: #AFBBC6;
						}
					}

					.input-group-text {
						background-color: rgba(255, 255, 255, 1);
						color: #AFBBC6;
						font-size: 20px;

						.fa-times {
							font-weight: 400;
						}
					}
				}
			}
		}

		.navbar-nav {
			flex-grow: 1;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-direction: row;

			li.nav-item {

				a.nav-link {
					@include ff-sans-serif();
					font-weight: 600;
					font-size: 18px;
					color: #FFF;
					letter-spacing: 0;
					transition: all .4s ease;
					line-height: 23px;

					&.btn {
						padding: 10px 24px;
						margin-left: 32px;

						&:hover {
							color: #128851;
						}
					}

					&:hover {
						color: rgba(255, 255, 255, .70);
					}
				}

				&.search-nav-mobile {

					a {
						text-decoration: none;

						&.hide {
							display: none;
						}

						&.close-btn {
							font-size: 40px;
							// line-height: 100%;
							font-weight: 300;
							line-height: 23px;
							@include ff-sans-serif();
						}
					}

					.fa-search {
						font-size: 24px;
					}
				}

				&.filter-nav-mobile {

					.fa-filter {
						font-size: 24px;
					}
				}
				&.cart-nav {
					text-align: center;
					a {
						font-size: 18px;
					}
				}


				&.menu-nav {
					text-align: center;
					a {
						font-size: 18px;

						i {
							font-size: 28px;
							margin-left: 8px;
						}
					}

					img {
						width: 30px;
					}
				}
			}
		}
	}
}