.card {
    font-family: 'Source Sans Pro';
    position: relative;

    .card-img-top {
        max-width: 100%;
    }

    // @in stock badge
    .badge-inventory {
        position: absolute;
        left: 14px;
        top: 14px;
        display: block;
    }

    &.checkout-card {
        background: white;
        margin-bottom: 2rem;

        &.notes-wrapper {
            // @include media-breakpoint-up(lg) {
            //     position: sticky;
            //     top: 90px;
            // }
        }

        .card-header {
            border-bottom: 1px solid #EEEEEE;
            padding: 1rem;
            background: white;

            h2 {
                padding: 0;
                margin: 0;
            }
        }

        .card-body {
            padding: 1rem;

            button {
                // margin-left: auto;
                // margin-right: auto;
                // display: block;
            }
        }

        .shipping-information-form {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .card-block {
        padding: 1.25rem;
    }

    .login-supplemental {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;

        .forgot-password {
            font-weight: bold;
            font-size: 18px;
        }
    }
}

#giftcard-form {
    .card {
        margin-top: 2rem;

        &:first-child {
            margin-top: 0;
        }

        .form-check-input {
            margin-top: 0;

        }

        .form-check-label {
            input {
                display: inline-block;
                margin-left: 0.3125rem;
                widtH: 150px;
            }
        }
    }
}



// @in stock badge
.badge-inventory {
    padding: 1px 10px 3px;
    color: #fff;
    border-radius: 3px;
    text-align: center;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, .2);
    display: inline-block;

    br {
        display: none;
    }

    span {
        font-size: 12px;
        font-weight: 600;
        line-height: 10px;
    }

    &.badge-in-stock {
        background-color: $success;
    }

    &.badge-available-soon {
        background-color: #128851;
    }

    &.badge-out-of-stock {
        background-color: #a52c46;
    }
}