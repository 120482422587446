section.user-search-query {
	// background: #45381F;
	background: #F3F3F3;
	color: #FFF;
	padding-top: 21px;
	padding-bottom: 21px;

		p {
			margin: 0;
			padding: 0;
			@include ff-sans-serif();
			font-size: 18px;
			color: #878787;
			letter-spacing: 0;
		}
}

section.results-found {
	padding-top: 20px;

	p {
		margin: 0;
		padding: 0;
		@include ff-sans-serif();
		font-size: 18px;
		color: #878787;
	}

	img.missing-plant {
		max-width: 150px;
		width: 100%;
		height: auto;
		@include max(sm) {
			max-width: 100px;
		}
	}
}
