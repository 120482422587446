.main-footer {
	background-color: #302614;
	color: #FFF;
	padding-top: 40px;
	position: relative;

		h3 {
			// font-family: Dosis-Bold;
			font-weight: 600;
			font-size: 22px;
			color: #AEABA3;
			letter-spacing: 0;
			line-height: 28px;
			margin-top: 0;
		}

		p,
		address {
			// font-family: SourceSansPro-Regular;
			@include ff-sans-serif();
			font-weight: 400;
			font-size: 16px;
			color: #AEABA3;
			letter-spacing: 0;
			line-height: 20px;

				a {
					color: #FFF;
					text-decoration: underline;

						&:hover {
							text-decoration: none;
						}
				}
		}

		img.plant-guide {
			width: 100%;
			height: auto;
			position: relative;
			bottom: 0px;
			@media screen and (max-width: 1200px) {
				max-width: 210px;
			}
		}
}

.sub-footer {
	background-color: #372D18;
	padding-top: 22px;
	padding-bottom: 22px;
	position: relative;

		p {
			// font-family: SourceSansPro-Regular;
			@include ff-sans-serif();
			font-weight: 400;
			font-size: 16px;
			color: #AEABA3;
			letter-spacing: 0;
			line-height: 20px;
			margin: 0;
		}

		.back-to-natorp {
			position: absolute;
			z-index: 10;
			@media screen and (max-width: 768px) {
				position: relative;
				z-index: auto;
			}

				a {
					color: #FFF;
					text-decoration: underline;

						&:hover {
							text-decoration: none;
						}

						.fa {
							margin-right: 8px;
						}
				}
		}
}