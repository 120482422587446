section.slide-out-menu {
	// background: rgba(47,47,46,0.50);
	position: absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index: -50;
	opacity: 0;
	// transition: all .2s ease;

		&.slide {
			z-index: 9000;
			opacity:1;

				&.in {

				  	.menu-container {
						  display: block;
			  			right:0;
			  			opacity: 1;

			  				button.submit-filters {
			  					width: 100%;
			  					padding-left: 0;
			  					padding-right: 0;
			  				}

			  				a.reset {
			  					width: 100%;
			  					padding-left: 0;
			  					padding-right: 0;
			  				}
				  	}
				}
		}

		&.slide-complete {
			display: block;
		}

		.slide-overlay {
			background: rgba(47,47,46,0.50);
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.menu-container {
			background-color: #fff;
			display: none;
			height: 100%;
			overflow: auto;
			position: fixed;
			right:-280px;
			top:0;
			// width: 20em;
			width: 370px;
			max-width: 100%;
			z-index: 9999;
			opacity: 0;
			transition: all .4s ease;

				.top {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					padding-top: 20px;
					padding-bottom: 20px;
					border-bottom: 1px solid #EAEAEA;
					margin-bottom: 16px;

						a.close-menu {
							display: flex;
							align-items: center;
							// justify-content: center;

								img {
									position: absolute;
									left: 16px;
									top: 22px;
									bottom:0;
									flex-grow: 1;
									max-width: 18px;
									width: 100%;
									height: auto;
								}
						}

						h2 {
							// flex-grow: 2;
							// font-family: SourceSansPro-Semibold;
							@include ff-sans-serif();
							font-weight: 600;
							font-size: 18px;
							color: #575656;
							letter-spacing: 0;
						}
				}
				@include media-breakpoint-down(md) {
					#filter-actions {
						position: sticky;
						top: 0;
						z-index: 1001;
						background-color: #fff;
						padding-top: 15px;
						> div:first-child {
							margin-top: 0 !important;
						}
					}
				}
		}
}