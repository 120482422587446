section.home-page-filters {

		.divider {
			height: 1px;
			background-color: #E1E8EE;
			width: 100%;
			display: block;
		}

		p.choose-below {
			@include ff-sans-serif();
			font-size: 24px;
			color: #000000;
			letter-spacing: 0;
			text-align: center;
			margin: 0;
			@media screen and (max-width: 768px) {
				font-size: 18px;
				margin: 20px 0;
			}
		}

		p.refine-search {
			@include ff-sans-serif();
			font-weight: 600;
			font-size: 18px;
			color: #575656;
			letter-spacing: 0;
			margin: 0;
		}

	// Sticky apply filters button on mobile
	@include media-breakpoint-down(md) {
		.filter-buttons {
			background-color: #eee;
		}
		&.has-filters {
			.filter-buttons {
				position: sticky;
				bottom: -54px;
				&:not(.scrolled-into-view) {
					animation: fadeInUp .2s;
				}
			}
		}
	} 
}

.filters-container {
	margin-top: 3rem;
		@media screen and (max-width: 768px) {
			margin-top: 21px;
		}

		p.filter-title {
			@include ff-sans-serif();
			font-weight: 600;
			font-size: 14px;
			@include max(lg) {
				font-size: 16px;
			}
		}

		.fa {
			margin-right: 16px;
			font-size: 21px;
			@include max(lg) {
				font-size: 23px;
			}
			cursor: pointer;
		}

		.icon-wrap {
			background-color: #F5F5F5;
			border-radius: 4px;
			padding-top: 20px;
			padding-bottom: 20px;
			margin-bottom: 7px;
			border: 2px solid #F5F5F5;
			transition: all .4s ease;
			cursor: pointer;

				&:hover {
					box-shadow: 0 0 16px 0 #999998;
				}

				&.active {
					border: 2px solid #128851;
					box-shadow: 0 0 5px 0 #43712D;
				}
		}

		.color-wrap {
			width: 44px;
			height: 44px;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			cursor: pointer;
			position: relative;
			margin: 0 auto;

				&.active {

						img {
							opacity: 1;
						}
				}

				img {
					opacity: 0;
					transition: opacity .4s ease;
				}
		}

		.month-wrap,
		.attribute-wrap {
			cursor: pointer;
			margin-bottom: 16px;

				&:hover {

						.checkbox {
							border: 1px solid #128851;
							box-shadow: 0 0 3px 0 #43712D;

								img {
									opacity: 1;
								}
						}
				}

				&.active {

						.checkbox {
							background-color: #128851;

								img {
									opacity: 1;
								}
						}
				}

				.checkbox {
					background: #E1E8EE;
					border-radius: 4px;
					height: 23px;
					width: 23px;
					margin-right: 8px;
					transition: all .4s ease;

						img {
							max-width: 13px;
							opacity: 0;
							transition: all .4s ease;
						}
				}

				p {
					margin: 0;
					@include ff-sans-serif();
					font-size: 16px;
					color: #575656;
					letter-spacing: 0;
					line-height: 20px;
				}
		}

		p {
			margin: 0;
			margin-bottom: 13px;
			@include ff-sans-serif();
			font-size: 16px;
			color: #575656;
			letter-spacing: 0;
			line-height: 20px;

				&.small {
					font-size: 12px;
					text-align: center;
				}
		}
}

@media screen and (max-width: 768px) {
	a.submit-filters {
		margin-bottom: 8px;
	}

	a.submit-filters,
	a.view-all-cats {
		display: block;
		width: 100%;
	}
}


.slide-down-filter-container {
	background-color: $white;
	
	.collapse-link {
		display: block;
		text-align: center;
		font-size: 16px;
		color: #39261F;
		padding: 17px 15px;

		i {
			margin-left: 5px;
		}


		.show {
			display: none;
		}
		.hide {
			display: block;
		}

		&.collapsed {

			.show {
				display: block;
			}
			.hide {
				display: none;
			}
		}
	}

	.home-page-filters {
		margin-bottom:0 !important;
		padding: 15px 0 30px;

		.filters-container {
			margin-top:0 !important;
			
			.query-filter-buttons {
				text-align: center;
				.cancel-filters {
					display: inline-block;
					margin-top: 15px;
				}
			}
		}
		
	}
}