































table.table-order{

    td {

        &[data-col="size"] {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;
        }
        &[data-col="title"]{
            @media screen and (max-width: 600px) {
                background: #ddd;
                font-weight: bold !important;
                border: 2px solid #dee2e6;
                border-radius: 8px 8px 0 0;
            }

            &.hide-desktop {
                visibility: hidden;
    
                @media screen and (max-width: 600px) {
                    visibility: visible;
                }
    
            }
        }
        &[data-col="showMore"] {
            display: none;

            @media screen and (max-width: 600px) {
                display: flex;
                justify-content: center;
                padding-top: 0.25rem !important;
                
                &:before{
                    display: none;
                }
            }
        }

        @media screen and (max-width: 600px) {
            padding-top: 0.25rem !important;
            padding-bottom: 0.25rem !important;
        }

    }
}
