// Margin / Padding support with media queries
$dirs: (t,r,b,l,x,y,a);
$steps: (up,down);
// @warn( $grid-breakpoints );
@for $i from 0 through 4 {
	$dist: ($grid-gutter-width-base / 2 * $i);
	@each $bp in $grid-breakpoints {
		@each $step in $steps {
			@if $step == up {
				// may need to move query inside class
				@include min(nth($bp, 1)) {
					@each $dir in $dirs {
						.m-#{$dir}-#{$i}-#{nth($bp, 1)}-#{$step} {
							@if $dir == t or $dir == y or $dir == a { margin-top: #{$dist} !important; }
							@if $dir == r or $dir == x or $dir == a { margin-right: #{$dist} !important; }
							@if $dir == b or $dir == y or $dir == a { margin-bottom: #{$dist} !important; }
							@if $dir == l or $dir == x or $dir == a { margin-left: #{$dist} !important; }
						}//@margin
						.p-#{$dir}-#{$i}-#{nth($bp, 1)}-#{$step} {
							@if $dir == t or $dir == y or $dir == a { padding-top: #{$dist} !important; }
							@if $dir == r or $dir == x or $dir == a { padding-right: #{$dist} !important; }
							@if $dir == b or $dir == y or $dir == a { padding-bottom: #{$dist} !important; }
							@if $dir == l or $dir == x or $dir == a { padding-left: #{$dist} !important; }
						}//@padding
					}//@dir
				}//@media
			}//@step_if
			@else {
				@include max(nth($bp, 1)) {
					@each $dir in $dirs {
						.m-#{$dir}-#{$i}-#{nth($bp, 1)}-#{$step} {
							@if $dir == t or $dir == y or $dir == a { margin-top: #{$dist} !important; }
							@if $dir == r or $dir == x or $dir == a { margin-right: #{$dist} !important; }
							@if $dir == b or $dir == y or $dir == a { margin-bottom: #{$dist} !important; }
							@if $dir == l or $dir == x or $dir == a { margin-left: #{$dist} !important; }
						}//@margin
						.p-#{$dir}-#{$i}-#{nth($bp, 1)}-#{$step} {
							@if $dir == t or $dir == y or $dir == a { padding-top: #{$dist} !important; }
							@if $dir == r or $dir == x or $dir == a { padding-right: #{$dist} !important; }
							@if $dir == b or $dir == y or $dir == a { padding-bottom: #{$dist} !important; }
							@if $dir == l or $dir == x or $dir == a { padding-left: #{$dist} !important; }
						}//@padding
					}//@dir 
				}//@media
			}//@step_if
		}//@step
	}//@grid-breakpoints
}//@loop