@mixin on-action() {
	&:hover,
	&:active,
	&:focus {
		outline: none;
		@content;
	}
}

.hover {
	transition: all .3s;
	> * {
		transition: all .3s;
	}
	&:hover {
		&.opacity {
			opacity: .7;
			> * {
				opacity: .7;
			}
		}
	}
}