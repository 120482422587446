// @.modal - ref [v4-alpha.getbootsrap.com/components#modal]
.modal {
	font-family: "Source Sans Pro";
	
	&.modal--checkout{
		.modal-dialog {
			.modal-content {
				padding:20px;
				[class*="modal"] { }
				.modal-header {
					background-color: #128851;
					color: #fff;
					img {
						margin-right: 1rem;
						height: 36px;
					}
					.modal-title {
						font-size: 1.75rem;
					}
					@include media-breakpoint-down('md') {
						img {
							height: 30px;
						}
						.modal-title {
							font-size: 1rem;
						}
					}
				} 
				.modal-body {
					padding-top: 1.5rem;
					text-align: center;
					> * {
						margin-bottom: 1rem;
						max-width: 100%;
						width: 500px;
						margin-left: auto;
						margin-right: auto;
						&:last-child {
							margin-bottom: 0;
						}
					}
					h1, h2, h3, h4, h5, h6 {
						text-transform: uppercase;
						font-weight: bolder;
					}
					p {
						font-size:18px;
					}

				}
				.modal-footer {
					border-top: none;
					.btn {
						background-color: #128851 !important;
						padding-left: 3rem;
						padding-right: 3rem;
						&:hover,
						&:focus,
						&:active {
							background-color: darken($color: #128851, $amount: 5%) !important;
						}
					}
				}
				.close {
					padding: 1rem;
					.close-icon {
						display: block;
						width: 1.5rem;
						height: 1.5rem;

						position: relative;
						&:before,
						&:after {
							content: '';
							position: absolute;
							left: 0;
							top: 50%;
							display: block;
							width: 100%;
							height: 2px;
							background-color: #fff;
						}
						&:before {
							transform: rotate(45deg);
						}
						&:after {
							transform: rotate(-45deg);
						}
					}
					@include on-action() { opacity: 1; }
				}
			}
		}
	}

	&.replacement-modal {
		.modal-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}