section.intro-hero {

	.hero-wrap {
		background-image: url(/assets/images/hero-image.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		padding-top: 50px;
		padding-bottom: 58px;

		// @media screen and (max-width: 992px) {
		@media screen and (max-width: 768px) {
			background-image: url(/assets/images/hero-image-mobile.jpg);
			background-position: center;
			min-height: 450px;
			padding-top: 18px;
			padding-bottom: 38px;
		}

		h1 {
			// font-weight: 700;
			font-size: 20px;
			line-height: 25px;
			font-weight: normal;
			color: #575656;
			letter-spacing: 0;
			margin-bottom: 16px;
			text-transform: uppercase;

			@media screen and (max-width: 768px) {
				font-size: 14px;
				line-height: 18px;
				margin-bottom: 5px;
			}
		}

		h2 {
			font-size: 48px;
			line-height: 48px;
			font-weight: 700;
			color: #575656;
			text-transform: uppercase;
			margin-top: 0;
			margin-bottom: 70px;

			@media screen and (max-width: 768px) {
				font-size: 24px;
				line-height: 32px;
				margin-bottom: 5px;
			}

		}

		p {
			@media screen and (max-width: 768px) {
				font-size: 14px;
				max-width: 70vw;
			}

		}

		.home-buttons {
			display: flex;
			text-align: center;

			@media screen and (max-width: 768px) {
				max-width: 80vw;
			}

			>div {
				margin-right: 15px;

				@media screen and (max-width: 768px) {
					margin-right: 0;
				}

				.btn {
					margin-bottom: 15px;

					@media screen and (max-width: 768px) {
						display: block;
						padding-left: 0;
						padding-right: 0;
					}
				}
			}


			@media screen and (max-width: 768px) {
				display: block;
			}

			@include media-breakpoint-down(sm) {
				width: 100%;
				max-width: 100%;
				.btn {
					display: block;
				}
			}
		}

		&.hero-wrap-wholesale {
			padding-top: 2.5rem;
			padding-bottom: 3.5rem;

			background-image: url(/assets/images/wholesale_hero.jpg);

			h2 {
				font-size: 1.5rem;
				font-weight: 600;
				color: #fff;
				margin: 0 0 .5rem;
			}
			h1 {
				font-size: 40px;
				line-height: 1.2;
				color: #fff;
				margin: .5rem 0 2rem;
			}
			p {
				color: #fff;
				margin: 1rem 0 2.5rem;
			}

			@include media-breakpoint-down(md) {
				text-align: center;
			}
		}
	}
}

#wholesale-app {
	.intro-hero {
		.hero-wrap {
			min-height: 0;
			padding-top: 50px;
			padding-bottom: 50px;
		}
	}
}