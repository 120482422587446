//@disply settings

.force-block {
	display:block;
}

$breaks:  mapReverse($grid-breakpoints);
@each $bp in $breaks {
	@media screen and (max-width: #{nth($bp, 2)}) {
		.block-#{nth($bp, 1)} {
			display:block !important;
		}
	}
}

// Responsive visibility utilities

@each $bp in map-keys($grid-breakpoints) {
	.hidden-#{$bp}-up {
	  @include media-breakpoint-up($bp) {
		display: none !important;
	  }
	}
	.hidden-#{$bp}-down {
	  @include media-breakpoint-down($bp) {
		display: none !important;
	  }
	}
  }
  
  .text-xs-center {
	@include media-breakpoint-down(sm) {
		text-align: center !important;
	}
  }