//@group form
.form-group {
  label {
    font-weight: 600;

    input[type="checkbox"]{
      margin-right: 10px;
    }
  }

  .input-group{

    .input-group-btn{

      button{
        background: #EEE;
        color: #373a3c;
        padding: .5rem 1rem;
        line-height: 1.25;
        font-size: 14px;
        border: 1px solid rgba(0,0,0,.15);

        &:hover{
          background: #ccc;
        }
      }
    }
  }
}

.help-block{
  color: red;
  padding-left: 1rem;
}