// Media Query Helper based on defined $grid-breakpoints from TBSv4.0.2
// these are imported via /vendor/bootstrap/_variables
// 
// @max(bp) is exclusive [i.e. does not affect the screen size at the breakpoint]
// @min is inclusive [i.e. does affect the screen size at breakpoint]

// @media query helper
@mixin mq($limiter, $bp) {
	$expr: "screen and (#{$limiter}-width: #{$bp})";
	@media #{$expr} {
		@content;
	}
}

// @max-width helper
@mixin max($break) {
	$bp_int: strip-unit( map-get($grid-breakpoints, $break) ) - 1;
	$bp: $bp_int + "px";
	@include mq('max', $bp) {
		@content;
	}
}

// @min-width helper
@mixin min($break) {
	$bp: map-get($grid-breakpoints, $break);
	@include mq('min', $bp) {
		@content;
	}
}

// @between helper
@mixin between($min, $max) {
	$low: map-get($grid-breakpoints, $min);
	$high_int: strip-unit( map-get($grid-breakpoints, $max) ) - 1;
	$high: $high_int + "px";
	$expr: "screen and (max-width: #{$high}) and (min-width: #{$low})";
	@media #{$expr} {
		@content;
	}
}