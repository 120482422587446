// Typography
$font-size: 1rem; //14px;
$font-line-height: 1.6;
$font-color: #333;

// Social Colors
$color-facebook:  #3b5998;
$color-twitter:   #00aced;
$color-google:    #dd4b39;
$color-youtube:   #bb0000;
$color-linkedin:  #007bb6;
$color-instagram: #517fa4;
$color-flickr:    #ff0084;
$color-pinterest: #cb2027;

// Links
$link-color: $primary;
$link-color-hover: darken($primary, 15%);

// Brand Colors - concatenate with any addition (name, hex) pairs per theme
$taupe: #d2d0cc;
$forest: #128851;

// Array for use in _color.scss
$brand-colors: 
	(font, $font-color),
	(white, white),
	(black, black),
	(primary, $primary),
	(success, $success),
	(info, $info),
	(warning, $warning),
	(danger, $danger),
	(taupe, $taupe),
	(forest, $forest);