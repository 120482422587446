











.form-group {
    font-size: 16px !important;
    position: relative;
}
.form-control {
    padding-left: 2em;
    font-size: 16px;
    height: calc(2.25em + 2px);
}
.input-addon {
    position: absolute;
    left: .75em;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #128851;
}
