.disclaimer-bar {
    padding: 10px;
    background-color: $brown;
    position: sticky;
    top:-1px;
	z-index: 1001;

    p {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 1.3;

        @media screen and (max-width: 768px) { 
            font-size: 10px;
        }
    }

    a {
        color: $white;
        text-decoration: underline;
    }
}