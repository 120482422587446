// @google Fonts
@if $googlefonts != false {
	@each $font in $googlefonts {
		@import url('https://fonts.googleapis.com/css?family=#{$font}:100,200,300,400,500,600,700,800,900');
	}
}

// @custom Fonts
// @if $customfonts != false {
// 	@each $font in $customfonts {
// 		@font-face {
// 			font-family: nth($font,1);
// 			src: url(nth($font,2)) format(nth($font,3));
// 			font-weight: nth($font,4);
// 			font-style: nth($font,5);
// 			// -webkit-font-smoothing: antialiased;
// 			// font-smoothing: antialiased;
// 			// font-smooth: always;
// 		}
// 	}
// }


// @general typography
$font-family-dosis: 		"Dosis", Helvetica, Arial, sans-serif;
$font-family-sans-serif:  	"Source Sans Pro", Helvetica, Arial, sans-serif;
$font-family-serif:       	"Times New Roman", Times, serif;
$font-family-monospace:   	Monaco, Menlo, Consolas, "Courier New", monospace;

// @font inclusion mixins

@mixin ff-dosis() {
	font-family: $font-family-dosis;
	font-smoothing: antialiased;
}

@mixin ff-sans-serif() {
	font-family: $font-family-sans-serif;
	font-smoothing: antialiased;
}

@mixin ff-serif() {
	font-family: $font-family-serif;
}

@mixin ff-mono() {
	font-family: $font-family-monospace;
}

// @font extensions
.ff-base {
	// @include ff-sans-serif();
	@include ff-dosis();
}

.ff-body {
	// @include ff-sans-serif();
	@include ff-sans-serif();
}

.ff-header {
	// @include ff-sans-serif();
	@include ff-dosis();
}

.ff-navbar {
	// @include ff-sans-serif();
	@include ff-dosis();
}