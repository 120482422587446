


























































































































































































.input-group {
    .btn {
        padding: .375rem 1.25rem;
        line-height: inherit;
        font-size: inherit;
    }
}
