///@Gift Cards
.gift-cards-promo {
    background-image: url(/assets/images/categories/grass@2x.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        
        display: block;
        background-color: $black;
        opacity: .3;
    }

    .container {
        position:relative;
    }

    padding: 2rem 0;
    @include media-breakpoint-up('md') {
        padding: 6rem 0;

        .promo-text {
            margin: 0 auto;
            max-width: 100%;
            width: 750px;
        }

        p.h1 {
            font-size: 3.5rem;
        }
        p:not(.h1) {
            font-size: 1.25rem;
        }
    }

    p:not(.h1) {
        margin: 2rem 0 3rem;
    }

}