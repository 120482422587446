// @group checkout table wrapper
#checkout-wrapper {
    padding-left: 3rem;
    padding-right: 3rem;

    @media screen and (max-width: 768px) {
        padding: 0;
    }

    .cart-contents {
        position: relative;

        .cart-contents-wrapper {
            position: relative;

            >form {
                >.row {
                    @media screen and (max-width: 768px) {
                        margin: 0;
                    }

                    >div {
                        @media screen and (max-width: 768px) {
                            padding: 0;
                        }
                    }
                }
            }

            >.row {
                @media screen and (max-width: 768px) {
                    margin: 0;
                }

                >div {
                    @media screen and (max-width: 768px) {
                        padding: 0;
                    }
                }
            }
        }

        .cart-updating {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            background-color: rgba(255, 255, 255, .75);
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            display: none;
            transition: all .3s;
            z-index: 1000;
            font-size: 20px;

            &.in {
                display: flex;
                opacity: 1;
            }
        }

        .upsell {

            .upsell-cart-item {

                .bg-image {
                    width: 75px;
                    height: 75px;
                    float: left;
                    background-position: center center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    a.title {
        text-decoration: underline;
    }
}

// @group table
table {
    //@variables
    $accent_grey: #e5e5e4;
    $accent_green: #128851;
    $accent_brown_light: #45371E;
    $accent_brown_dark: #372D18;

    td,
    th {
        padding: .75rem 1rem !important;
    }

    &.table-checkout {
        thead {
            background-color: lighten(#AEABA3, 15%);

            th {
                font-weight: bold;
            }
        }

        tr {
            position: relative;
            padding: 0 !important;

            td {
                border-top: none;
                border-bottom: 1px solid #eceeef;
            }
        }

        tr td,
        tr th {
            vertical-align: middle;
            position: relative;
            padding: .75rem 1rem !important;
        }

        img {
            height: 3.5rem;
            width: auto;
        }

        @include max(md) {
            tr {

                td,
                th {

                    &:first-child,
                    &:nth-child(2) {
                        // display: none;
                    }
                }
            }
        }

        // @tr
        .cart-item {
            .bg-image {
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                width: 75px;
                height: 75px;
                border-radius: 4px;

                &.large {
                    width: 150px;
                    height: 102px;
                }

                @include max(md) {
                    width: 60px;
                    height: 60px;

                    &.large {
                        width: 120px;
                        height: 80px;
                    }

                    margin-bottom: 20px;
                    margin-right: 8px;
                }
            }

            .cart-item-quantity {
                margin-top: 4px;

                button,
                span {
                    width: 1.75rem;
                    height: 1.75rem;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                }

                // @+/- buttons
                button {
                    padding: 0;
                    border: none;
                    transition: all .3s;
                    border-radius: 5px;
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    height: 1.5rem;
                    line-height: 1.5;

                    &.remove {
                        background-color: lighten($accent_grey, 10%);
                        background-color: white;
                        border: 1px solid $accent_green;

                        // color: white;
                        &:hover {
                            background-color: $accent_green;
                            color: white;
                        }
                    }

                    &.add {
                        background-color: darken($accent_grey, 5%);
                        background-color: $accent_green;
                        border: 1px solid $accent_green;
                        color: white;

                        &:hover {
                            background-color: white;
                            color: black;
                        }
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: darken($accent_green, 10%);
                        color: #fff;
                    }

                    &.disabled {
                        cursor: not-allowed;
                    }
                }
            }

            // @confirm remove dialog
            .remove-confirm {
                position: absolute;
                z-index: 10;
                background-color: #fff;
                right: 50%;
                width: 250px;
                padding: 10px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, .1);
                border-radius: 5px;
                opacity: 0;
                display: none;
                transition: all .3s;

                &.in {
                    display: block;
                    opacity: 1;
                }

                p {
                    margin-bottom: 8px;
                }

                .remove-button-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    button {
                        flex: 1;
                    }
                }

                button {
                    font-size: 16px;
                    padding: .25rem 1rem;

                    &+button {
                        margin-left: 8px;
                    }
                }
            }

            td {
                a {
                    font-size: 16px;
                    color: #111111;
                    letter-spacing: 0;
                    line-height: normal;
                }
            }
        }
    }

    &.table-totals {

        tr.pre-gift-card-total {
            border-bottom: 1px solid #eceeef;
        }

        tr.total {
            // background-color: lighten(#AEABA3, 15%);
            font-size: 1.5rem;
            border-top: 1px solid #eceeef;

            &.order-detail-total {
                border-bottom: 1px solid #eceeef;
            }
        }

        tr.lead {

            th,
            td {
                border-top: none;
            }
        }

    }
}

.cart-items_mobile {
    //@variables
    $accent_grey: #e5e5e4;
    $accent_green: #128851;
    $accent_brown_light: #45371E;
    $accent_brown_dark: #372D18;

    .cart-item {
        .cart-item-quantity {

            button,
            span {
                width: 1.75rem;
                height: 1.75rem;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }

            // @+/- buttons
            button {
                padding: 0;
                border: none;
                transition: all .3s;

                &.remove {
                    background-color: lighten($accent_grey, 5%);
                }

                &.add {
                    background-color: darken($accent_grey, 5%);
                }

                &:hover {
                    cursor: pointer;
                    background-color: $accent_green;
                    color: #fff;
                }

                &.disabled {
                    cursor: not-allowed;
                }
            }
        }

        //@card-header
        .card-header {
            position: relative;
        }

        // @confirm remove dialog
        .remove-confirm {
            position: absolute;
            z-index: 10;
            background-color: #fff;
            right: 10px;
            left: 10px;
            top: 50px;
            // width: 100%;
            padding: 10px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, .25);
            border-radius: 5px;
            opacity: 0;
            display: none;
            transition: all .3s;

            &.in {
                display: block;
                opacity: 1;
            }

            p {
                margin-bottom: 8px;
            }

            .remove-button-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;

                button {
                    flex: 1;
                }
            }

            button {
                font-size: 16px;
                padding: .25rem 1rem;

                &+button {
                    margin-left: 8px;
                }
            }
        }
    }
}

.checkout-intro {
    padding-top: 1.5rem !important;

    @media screen and (max-width: 768px) {
        padding: 1.5rem 15px 0 15px !important;
    }

    a.print-page {
        i.fa-print {
            font-size: 26px;
        }
    }
}

//Datepicker
.datepicker {
    z-index: 10000 !important;

    table.table-condensed {
        @media screen and (max-width: 600px) {
            tr {

                th,
                td {
                    padding: .5rem .75rem !important;
                }
            }
        }
    }
}

.content-wrapper {
    >.container {
        @media screen and (max-width: 768px) {
            padding: 0 !important;
            > .row {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    >.container-fluid {
        @media screen and (max-width: 768px) {
            padding: 0 !important;
        }
    }

    .cart-contents-wrapper {


        >.row {
            @media screen and (max-width: 768px) {
                margin: 0;
            }

            >div {
                @media screen and (max-width: 768px) {
                    padding: 0;
                }
            }
        }
    }
}