section.categories {

	a {

		&:hover {
			text-decoration: none;

			.card {
				box-shadow: 0 0 16px 0 #999998;
			}
		}
	}

	.card {
		padding: 8px;
		border-radius: 4px;
		background: #FFFFFF;
		border: 1px solid #E5E5E4;
		box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.05);
		// margin-bottom: 24px;
		transition: all .4s ease;

		img {
			width: 100%;
			height: auto;
		}

		.card-top {
			width: 100%;
			// height: 100%;
			min-height: 200px;
			background-size: cover;
			background-position: center;
		}

		.card-block {
			// padding: 1rem;
			padding: .70rem;

			h4.card-title {
				// font-family: SourceSansPro-Regular;
				@include ff-sans-serif();
				// font-size: 24px;
				color: #000000;
				letter-spacing: 0;
				line-height: 28px;
				margin: 0;
				margin-bottom: 1px;
				font-weight: 600;
				font-size: 22px;
			}

			p.card-text {
				// font-family: SourceSansPro-Regular;
				@include ff-sans-serif();
				font-size: 18px;
				color: #797676;
				letter-spacing: 0;
				line-height: 23px;
				margin: 0;
				font-weight: 500;
			}
		}
	}
}

.results-options {
	padding: 27px 0 5px;

	.col-md-12 {
		display: flex;
		justify-content: space-between;

		@media screen and (max-width: 768px) {
			display: block;
		}
	}

	.results-views {
		display: flex;
		align-items: flex-end;

		@media screen and (max-width: 768px) {
			justify-content: center;
		}

		a {
			display: inline-block;
			margin-left: 19px;
			color: #575656;
			font-size: 14px;
			text-align: center;
			text-decoration: none;

			&:first-child {
				margin-left: 0;
			}

			svg {
				stroke: #575656;
				margin-bottom: 5px;
			}

			&.active,
			&:hover {
				color: $primary;

				svg {
					stroke: $primary;
				}
			}
		}
	}

	.sort-toggle {
		display: flex;
		align-items: center;

		@media screen and (max-width: 768px) {
			margin-top: 15px;
			justify-content: center;
		}

		label {
			cursor: pointer;
			margin-left: 8px;
			font-size: 14px;
			color: #575656;
		}

		.switch {
			position: relative;
			display: inline-block;
			width: 62px;
			height: 30px;

			input {
				opacity: 0;
				width: 0;
				height: 0;

				&:checked+.slider {
					background-color: $primary;
					border-color: $primary;
				}

				&:focus+.slider {
					box-shadow: 0 0 1px $primary;
				}

				&:checked+.slider:before {
					transform: translateX(32px);
					background-color: $white;
					border-color: $white;
				}
			}

			.slider {
				position: absolute;
				cursor: pointer;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: $white;
				-webkit-transition: .4s;
				transition: .4s;
				border-radius: 34px;
				border: 1px solid #BEBEBE;

				&:before {
					position: absolute;
					content: "";
					height: 24px;
					width: 24px;
					left: 2px;
					bottom: 2px;
					background-color: #D8D8D8;
					-webkit-transition: .4s;
					transition: .4s;
					border-radius: 50%;
					border: 1px solid #979797;
				}
			}
		}
	}
}

.plants-table {
	$table-gray: darken(rgb(238, 238, 238), 1%);

	border-top: none;

	@media screen and (max-width: 768px) {
	}

	thead {

		@media screen and (max-width: 768px) {
			display: none;
		}

		th, td {
			border-bottom: none;
		}
	}

	th,td {
		@media screen and (max-width: 768px) {
			padding: .5rem .75rem !important;
		}

		&.plant-image {
			width:100px;

			@media screen and (max-width: 768px) {
				display: none;
			}
		}
		&.plant-link {
			@media screen and (max-width: 768px) {
				display: none;
			}
		}

		&.plant-status {
			min-width: 87px;
		}
	}

	th {
		background: $primary;
		color: #fff;
		letter-spacing: 1px;
		text-transform: uppercase;
		font-weight: bold;
	}

	tr {
		border-left: 2px solid $table-gray;
		border-right: 2px solid $table-gray;
		font-size: 16px;

		&:nth-child(even) {
			background-color: lighten(rgb(238, 238, 238), 4%);
		}
		&:nth-child(odd) {
			background-color: #fff;
		}

		td {
			border-top: 2px solid $table-gray;
			vertical-align: middle;

			.card-top {
				width: 100px;
				height: 80px;
				background-size: cover;
				background-position: center;
				border-radius: 4px;
			}

			a {
				color: $black;
				font-size: 16px;
				text-decoration: underline;
				
				&:hover {
					text-decoration: underline;
					color: $primary;
				}

				h4 {
					font-size: 21px;
					@media screen and (max-width: 768px) {
						font-size: 16px;
					}
				}
			}

			.card-text {
				font-size: 16px;
				line-height: 1.3;
				@media screen and (max-width: 768px) {
					font-size: 14px;
				}
			}
		}
	}
}