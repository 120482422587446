// @Wholesale Styles

body.wholesale-order {
    min-height: 100vh;
    
    // Hide footer on order page
    .main-footer,
    .sub-footer {
        display: none !important;
    }
}

#wholesale-app {
    .content-wrapper {
        background: white;
    }
    &.checkout-page{
        .content-wrapper {
            background: #EEE;
        }
    }

    /// @group Mobile
    @include media-breakpoint-down(md) {
        table.table-order {
            tbody {
                &.cart-item {
                    padding: 0;
                }
            }
        }
    }
}