





















tbody.plant-group {
    &.even {
        background-color: white;

        @media screen and (max-width: 600px) {
            background-color: white;
        }
    }
    &.odd{
        background-color: #E5E5E4;
        @media screen and (max-width: 600px) {
            background-color: white;
        }
    }
    @media screen and (max-width: 600px) {
        td:nth-child(3),
        td:nth-child(4),
        td:nth-child(5),
        td:nth-child(6),
        td:nth-child(7)
        {
            display: none !important;
        }
    }

    tr.show-all{
        td{
            display: flex !important;
        }
    }

}
